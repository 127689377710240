
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  getInvoiceData,
  initCouponData,
  CouponData,
  editStatus,
  getRecordList,
} from '@/api/marketing/coupon';
import { PaginationData } from '@/api/operation/customer';
const { VUE_APP_API: FCAPI } = process.env;
import storage from 'baimahu/storage';
import moment from 'moment';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const getUrl = require('baimahu/url-with-param');
const COUPON_TYPE = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '满减券',
    /* children: [
      {
        value: 1,
        label: '通用券',
      },
      {
        value: 2,
        label: '品类券',
      },
      {
        value: 3,
        label: '商品券',
      },
    ], */
  },
  {
    value: 2,
    label: '折扣券',
    /* children: [
      {
        value: 1,
        label: '通用券',
      },
      {
        value: 2,
        label: '品类券',
      },
      {
        value: 3,
        label: '商品券',
      },
    ], */
  },
];
const STATUS = [
  {
    value: 0,
    label: '全部',
  },
  {
    value: 1,
    label: '上架中',
  },
  {
    value: 2,
    label: '已下架',
  },
];
//范围类型
const scopeType = [
  {
    value: 0,
    lable: '全部',
  },
  {
    value: 1,
    lable: '通用券',
  },
  {
    value: 3,
    lable: '品类券',
  },
  {
    value: 2,
    lable: '商品券',
  },
];
//发放类型
const grantType = [
  {
    value: 0,
    lable: '全部',
  },
  {
    value: 1,
    lable: '手动领取',
  },
  {
    value: 2,
    lable: '券码兑换',
  },
  {
    value: 3,
    lable: '系统发放',
  },
  {
    value: 4,
    lable: '分享领取',
  },
  {
    value: 5,
    lable: '定点空投',
  },
];
//用户类型
const userType = [
  {
    value: 0,
    lable: '全部',
  },
  {
    value: 1,
    lable: '全部用户',
  },
  {
    value: 2,
    lable: '新用户',
  },
  {
    value: 3,
    lable: '老用户',
  },
  {
    value: 4,
    lable: 'APP会员',
  },
];
//有效期类型
const expireType = [
  {
    value: 0,
    lable: '全部',
  },
  {
    value: 1,
    lable: '时间段',
  },
  {
    value: 2,
    lable: '领券当日起',
  },
  {
    value: 3,
    lable: '领券次日起',
  },
];
function initForm() {
  return {
    type: 0, //类型，1满减券，2折扣券
    //coupon_type: [0],
    status: 0, //状态，1上架中，2已下架
    name: '',
    range: 0, //1：通用 2：商品 3：品类
    grant_type: 0, //发放类型 1：手动领取 2：券码兑换 3:系统发放 4:分享领取
    user_type: 0, //用户类型：1全部用户，2新用户，3老用户
    expire_type: 0, //有效期类型：1开始结束时间，2领券当日起天数，3领券次日起天数
    create_time: [],
    expire_days: '', //有效期天数
  };
}
@Component({
  name: 'Coupon',
})
export default class Coupon extends Vue {
  setGlobalFunction = useGlobalFunction().setGlobalFunction;
  moment: any = moment;
  form_value: any = initForm();
  is_loading: boolean = false;
  coupon_data: PaginationData<CouponData> = initCouponData();
  used_data: any = {};
  received_data: any = {};
  page: any = 1;
  modal_value: any = {
    modal_type: 1,
    data: {},
    item: {},
    is_show_coupon_detail: false,
    is_show_status_modal: false,
  };

  get couponType() {
    return COUPON_TYPE;
  }
  get couponStatus() {
    return STATUS;
  }
  //范围类型
  get couponFanwei() {
    return scopeType;
  }
  //发放类型
  get grantType() {
    return grantType;
  }
  //用户类型
  get userType() {
    return userType;
  }
  //有效期类型
  get expireType() {
    return expireType;
  }
  clearForm() {
    this.form_value = initForm();
    this.clickSearchBtn();
    //
  }
  addCoupon() {
    storage.removeItem('coupon_type');
    this.$router.push('/marketing/add-coupon');
  }
  paginationOption(data?: any) {
    return {
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      total: data.total || 0,
      pageSize: data.limit || 1,
      current: +data.current || 1,
    };
  }

  // 切换上下架状态
  @changeLoading(['is_loading'])
  async changeStatus() {
    const res = await editStatus(
      this.modal_value.item.id,
      this.modal_value.item.status === 1 ? 2 : 1
    );
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.modal_value.is_show_status = false;
    await this.getCouponList();
    this.modal_value.is_show_status_modal = false;
  }

  showStatusModal(item: any) {
    this.modal_value.is_show_status_modal = true;
    this.modal_value.item = item;
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async clickSearchBtn() {
    const query_params = {
      name: this.form_value.name,
      type: this.form_value.type,
      range: this.form_value.range,
      grant_type: this.form_value.grant_type,
      user_type: this.form_value.user_type,
      status: this.form_value.status,
      expire_type: this.form_value.expire_type,
      start_time:
        this.form_value.expire_type == 1 && this.form_value.create_time.length > 0
          ? this.form_value.create_time[0].format('X') || 0
          : 0,
      end_time:
        this.form_value.expire_type == 1 && this.form_value.create_time.length > 0
          ? this.form_value.create_time[1].format('X') || 0
          : 0,
      expire_days:
        this.form_value.expire_type == 2 || this.form_value.expire_type == 3
          ? this.form_value.expire_days
          : 0,
      points_exchange: this.form_value.points_exchange,
      /* type: this.form_value.coupon_type.length > 1 ? this.form_value.coupon_type[0] : '',
      range: this.form_value.coupon_type.length > 1 ? this.form_value.coupon_type[1] : '', */
    };
    if (!this.is_err) {
      await this.getCouponList(query_params);
    }
  }

  // 获取优惠券列表
  @changeLoading(['is_loading'])
  async getCouponList(
    query: any = {
      ...this.form_value,
      create_time: undefined,
      start_time:
        this.form_value.expire_type == 1 && this.form_value.create_time.length > 0
          ? this.form_value.create_time[0].format('X') || 0
          : 0,
      end_time:
        this.form_value.expire_type == 1 && this.form_value.create_time.length > 0
          ? this.form_value.create_time[1].format('X') || 0
          : 0,
      expire_days:
        this.form_value.expire_type == 2 || this.form_value.expire_type == 3
          ? this.form_value.expire_days
          : 0,
      points_exchange: this.form_value.points_exchange,
    }
  ) {
    console.log(query);

    const res = await getInvoiceData(query);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    if (JSON.parse(JSON.stringify(res.data)).length != 0) {
      res.data.detail.forEach((item: any) => {
        item['issued'] =
          item.grant_type == 1 ? item.received_number : item.grant_type == 2 ? item.code_count : 0;
      });
      this.coupon_data = initCouponData(res.data);
    } else {
      this.$message.error('暂无数据');
      this.coupon_data = {
        current: 0,
        detail: [],
        limit: 0,
        total: 0,
        last: 0,
      };
    }
  }

  // 展示已领取模态窗
  @changeLoading(['is_loading'])
  async showReceivedModal(item: any) {
    this.modal_value.is_show_coupon_detail = true;
    this.modal_value.item = item;
    this.modal_value.modal_type = 1;
    const res = await getRecordList({
      coupon_id: item.id,
      status: '0',
      current: 1,
      limit: 5,
    });
    if (res.status !== 200) {
      this.received_data = [];
      return;
    } else {
      this.received_data = res.data;
    }
  }

  // 展示已使用模态窗
  @changeLoading(['is_loading'])
  async showUsedModal(item: any) {
    this.modal_value.is_show_coupon_detail = true;
    this.modal_value.item = item;
    this.modal_value.modal_type = 2;
    const res = await getRecordList({
      coupon_id: item.id,
      status: 2,
      current: 1,
      limit: 5,
    });
    if (res.status !== 200) {
      this.used_data = [];
      return;
    } else {
      this.used_data = res.data;
    }
  }

  // 编辑优惠券
  editCoupon(item: any) {
    storage.setItem('coupon_type', {
      type: item.type,
      range: item.range,
    });
    this.$router.push(`/marketing/add-coupon-second?id=${item.id}`);
  }

  // 切换已使用数据分页
  @changeLoading(['is_loading'])
  async handleChangeUsedData(data: any) {
    this.page = data.current;
    const res = await getRecordList({
      coupon_id: this.modal_value.item.id,
      status: 2,
      current: data.current,
      limit: 5,
    });
    if (res.status !== 200) {
      return;
    }
    this.received_data = res.data;
  }

  // 切换已领取数据分页
  @changeLoading(['is_loading'])
  async handleChangeReceivedData(data: any) {
    this.page = data.current;
    const res = await getRecordList({
      coupon_id: this.modal_value.item.id,
      status: '0',
      current: data.current,
      limit: 5,
    });
    if (res.status !== 200) {
      return;
    }
    // this.used_data = res.data;
    this.received_data = res.data;
  }

  // 切换分页
  @changeLoading(['is_loading'])
  async nextPage(data: any) {
    /* const query_params = {
      name: this.form_value.name,
      status: this.form_value.status,
      type: this.form_value.coupon_type.length > 1 ? this.form_value.coupon_type[0] : '',
      range: this.form_value.coupon_type.length > 1 ? this.form_value.coupon_type[1] : '',
      current: data.current,
    }; */
    const query_params = {
      name: this.form_value.name,
      type: this.form_value.type,
      range: this.form_value.range,
      grant_type: this.form_value.grant_type,
      user_type: this.form_value.user_type,
      status: this.form_value.status,
      expire_type: this.form_value.expire_type,
      start_time: this.form_value.create_time[0] || '',
      end_time: this.form_value.create_time[1] || '',
      expire_days: this.form_value.expire_days,
      current: data.current,
      /* type: this.form_value.coupon_type.length > 1 ? this.form_value.coupon_type[0] : '',
      range: this.form_value.coupon_type.length > 1 ? this.form_value.coupon_type[1] : '', */
    };
    await this.getCouponList(query_params);
  }

  // 去券码页面
  goCouponCodePage(item: any) {
    this.$router.push(`/marketing/coupon-code?coupon_name=${item.name}&id=${item.id}`);
  }

  // 导出模态窗数据
  exportData() {
    if (
      JSON.parse(JSON.stringify(this.received_data)).detail == undefined &&
      this.modal_value.modal_type === 1
    ) {
      this.$message.error('暂无数据，导出失败');
    } else if (
      JSON.parse(JSON.stringify(this.used_data)).detail == undefined &&
      this.modal_value.modal_type != 1
    ) {
      this.$message.error('暂无数据，导出失败');
    } else {
      const url = getUrl(`${FCAPI}/boss/marketing/coupon/record`, {
        coupon_id: this.modal_value.item.id,
        status: this.modal_value.modal_type === 1 ? '0' : this.modal_value.modal_type,
        current: this.page,
        limit: 5,
        is_export: 1,
      });
      window.location.href = url;
    }
  }
  //验证是否为正整数
  is_err: boolean = false;
  validateInput(days: any, type: any) {
    const reg = /^[1-9]\d*$/;
    if (!reg.test(days) && (type == 2 || type == 3)) {
      this.is_err = true;
      return;
    } else {
      this.is_err = false;
    }
  }

  @changeLoading(['is_loading'])
  async created() {
    this.getCouponList();
    this.setGlobalFunction({ getCouponList: this.getCouponList });
  }
}
